import { Component, OnInit, OnDestroy} from "@angular/core";
import { Router,NavigationEnd } from "@angular/router";
import { DeviceService } from "src/app/device.service";
import { UtilityService } from "../../utility.service";
import { ReportService } from 'src/app/reports/report-service.service';
import { AuthGuardService } from 'src/app/auth/auth-guard.service';
import { Subscription } from 'rxjs';
declare var jQuery: any;

/**
 * DID-NEXUS-027
 * Header component
 */
@Component({
  selector: "app-application-menu",
  templateUrl: "./application-menu.component.html",
  styleUrls: ["./application-menu.component.css"]
})
export class ApplicationMenuComponent implements OnInit, OnDestroy {
  private helpDataSubscription: Subscription;
  private progressBarSubscription: Subscription;
  private newReportSubscription: Subscription;
  private newProviderSubscription: Subscription;
  toggle = false;
  toggle1 = false;
  toggle2 = false;
  public searchText = "";
  public url: string;
  public userName: string;
  public PageName: string;
  public pname: string;
  public rawUrl: string
  public helpData: string;
  public customReports: any = [];
  addedProviders: string[] = [];
  menuData:any;
  public clas: boolean = false;
  public expand_width: string = "300px";
  public collapse_width: string = "70px";
  public mob_width: any = 768;
  constructor(
    public router: Router,
    private deviceService: DeviceService,
    public utilityService: UtilityService,
    public reportService: ReportService,
    private authgaurd: AuthGuardService
  ) {
    const url = document.URL.split("/");
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.searchText = "";
        // Function you want to call here
      }
    });
    this.rawUrl = url[url.length - 1];
    console.log(document.URL);
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    // this.helpDataSubscription = this.utilityService.getHeaderHelpData().subscribe(data => { 
    //   this.helpData = data.helpdata;
    //   this.PageName = data.pageName;
     
      
    // });
 
    this.utilityService.getLangValue('help').then(res => {
  
      
      this.menuData=res.ApplicationMenu;
      console.log(this.menuData);
      

    });
    
    this.progressBarSubscription = this.utilityService.getLoaderflagStatus().subscribe(data => {
      this.utilityService.loaderFlag = data;
    });
    this.getData();
    console.log("debug", this.searchText);

    this.getCustomReports();
    this.newReportSubscription = this.reportService.getNewReportCreatedListener().subscribe(() => {
      this.getCustomReports();
    });

    this.getAddedLocationProviders();
    this.newProviderSubscription = this.deviceService.getNewProviderAddedListener().subscribe(() => {
      this.getAddedLocationProviders();
    });
  }

  /**
   * (openNav)
   * Description:
   * this function is called when user opens navigation drawer
   */
  openNav() {
    if ((this.clas = !this.clas) === true) {
      if (window.innerWidth < this.mob_width) {
        document.getElementById("mySidenav").style.left = '0';
      }

      document.getElementById("mySidenav").style.width = this.expand_width;
      document.getElementById("bg_mask").classList.remove("d-none");
      let i_none: NodeListOf<HTMLElement> = document.querySelectorAll("#allLinks a i");
      for (let i = 0; i <= i_none.length - 1; i++) {
        i_none[i].style.display = "block";
      }
    } else {
      this.closeNav();
    }
  }

  /**
   * (opn)
   * Description:
   * this function opens sub nav bar when user clicks it.
   */
  opn() {
    var wth = document.getElementById("mySidenav");
    wth.style.width = this.expand_width;
    document.getElementById("bg_mask").classList.remove("d-none");
    document.querySelector(".logo_section").classList.add("open");
    let i_none: NodeListOf<HTMLElement> = document.querySelectorAll(
      "#allLinks a i"
    );
    for (let i = 0; i <= i_none.length - 1; i++) {
      i_none[i].style.display = "block";
    }
    //console.log(wth.style.width);
  }

  /**
   * (closeNav)
   * Description:
   * this function hides nav after click
   */
  closeNav() {
    if (window.innerWidth < this.mob_width) {
      document.getElementById("mySidenav").style.left = '-75px';
    }
    document.querySelector(".logo_section").classList.remove("open");
    document.getElementById("mySidenav").style.width = this.collapse_width;
    document.getElementById("bg_mask").classList.add("d-none");
    this.toggle = false;
    this.toggle1 = false;
    this.toggle2 = false;
    let i_none: NodeListOf<HTMLElement> = document.querySelectorAll(
      "#allLinks a i"
    );
    for (let i = 0; i <= i_none.length - 1; i++) {
      i_none[i].style.display = "none";
    }

  }

  /**
   * (logOut)
   * Description:
   * this function logs out the user and redirect to login page.
   */
  logOut() {
    this.utilityService.logout().subscribe(res=>{
      localStorage.removeItem("AccessToken");
      localStorage.removeItem("userName");
      localStorage.removeItem("sessionId");
      this.authgaurd.clearPermission();
      this.utilityService.mapFlag = false;
      this.router.navigate(["/login"], { replaceUrl: true });
    });
  }

  /**
   * (onSearch)
   * Description:
   * this function sends text to child component for filter data
   */
  onSearch() {
    console.log("search",this.searchText);
    this.utilityService.setSearchDataonMap(this.searchText.toLocaleUpperCase());
    this.utilityService.setSearchData(this.searchText.toLocaleUpperCase());
    this.deviceService.passToDashboard(this.searchText.toLocaleUpperCase());
  }

  /**
   * (onClickActiveA)
   * Description:
   * this function highlights the selected nav
   */
  onClickActiveA(e) {
    var all_a = document.querySelectorAll("#allLinks a");
    var submenu_a = document.querySelectorAll(".submenu a");
    var cls = e.target.parentNode.className;
    if (cls.search("submenu") === -1) {
      all_a.forEach((item, index) => {
        item.classList.remove("active");
      });
    } else {
      submenu_a.forEach((item, index) => {
        item.classList.remove("active");
      });
    }
    e.target.classList.add("active");
    //console.log();
  }

  /**
   * (onKeyup)
   * Description:
   * this function adds class which is responsible for search width
   */
  onKeyup() {
    var search = document.querySelector(".search");
    var search_ul: HTMLElement = document.querySelector(".search ul");
    search.classList.add("w-100");
    //search_ul.style.height = '200px';
  }

  /**
   * (defaultInput)
   * Description:
   * this function removes class which is responsible for search width
   */
  defaultInput() {
    var search = document.querySelector(".search");
    var search_ul: HTMLElement = document.querySelector(".search ul");
    search.classList.remove("w-100");
  }

  /**
   * (getData)
   * Description:
   * Used to get user info after login if iSAS provides
   */
  getData() {
    this.userName = localStorage.getItem("userName");
  }

  /**
   * (customReportDetails)
   * Description:
   * this function shows custom report
   */
  customReportDetails(customReport) {
    this.closeNav();
    console.log(customReport);
    this.reportService.changeHeatMapListeners(this.customReports);
    this.helpData = customReport.Description;
    this.PageName = customReport.Name;
  }

  /**
   * (getCustomReports)
   * Description:
   * this function return all the custom report for navigation
   */
  getCustomReports() {
    this.reportService.get('custom.getAllCustomReports', res => {
      this.customReports = res;
      console.log("report data",this.customReports);

    }, err => {
      console.log(err);
    });
  }

  /**
   * (getAddedLocationProviders)
   * Description:
   * this function return all the added location providers for settings
   */
  getAddedLocationProviders() {
    this.utilityService.get('/api/settings/getAddedLocationProviders', res => {
      this.addedProviders = res;
    }, err => {
      console.log(err);
    });
  }

  /**
   * (ngOnDestroy)
   * Description:
   * this function call when angular destroyes the component
   */
  ngOnDestroy() {
    this.searchText = "";
    if (this.helpDataSubscription) { this.helpDataSubscription.unsubscribe(); }
    if (this.progressBarSubscription) { this.progressBarSubscription.unsubscribe(); }
    if (this.newReportSubscription) { this.newReportSubscription.unsubscribe(); }
    if (this.newProviderSubscription) { this.newProviderSubscription.unsubscribe(); }
  }

}
