import { Component, OnInit, ViewChild, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { truncate } from 'fs';

/**
 * DID-NEXUS-041, DID-NEXUS-043
 * Table component, used for data table generation
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy {
  displayedColumns: string[];
  dataSource: any = [];
  paginationFlag: number;
  paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Output() sendFilterData = new EventEmitter();
  @Output() sendPaginationDetails = new EventEmitter();
  @Output() sendPaginationDetailsForMednet = new EventEmitter();
  @Input() tableData;
  @Input() tableDataApi;
  private tableDataSubscription: Subscription;
  assetId:any;
  MetaDataMapping: any;
  tName: any;
  totalAssets: any;
  perPageItems: any;
  currentPage: any;
  searchObject = [];
  wholeSearch = 0;
  historyPopUpFlag = 1;
  AssetType;
  statusOption:any;
  restrictedFields = ['Action', 'Date & Time'];
  constructor(public utilityService: UtilityService,public deviceService: DeviceService, public router: Router) {
    this.deviceService.getData('asset.getMetaDataMapping', data => {
      this.MetaDataMapping = data;
      //console.log(this.MetaDataMapping);
    }, res => {
      console.log(res);
    });
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
     // get display columns form the report
     if (this.tableDataApi) {
      this.displayedColumns = this.tableDataApi.columns;
     }
     // get display columns for custom report
     this.tableDataSubscription = this.utilityService.getDataForTable().subscribe((fetchedData) => {
      if (Object.keys(fetchedData)) {
          console.log(fetchedData);
          const assets = fetchedData.reportData || [];
          const fields: any = fetchedData.fields || [];
          this.tName = fetchedData.tableName;
          this.paginationFlag = fetchedData.showPagination;
          this.displayedColumns = fields;
          console.log(this.displayedColumns);
          this.totalAssets = fetchedData.total || '';
          this.perPageItems = fetchedData.items || '';
          this.currentPage = fetchedData.currentPage || '';
          this.assetId = fetchedData.assetId || '';
          this.wholeSearch = fetchedData.wholeSearch;
          this.AssetType = fetchedData.reportData[0].AssetType || fetchedData.reportData[0]['Asset Type'] || fetchedData.assetType || 'Equipment';
          this.utilityService.setDataForExportTable(this.displayedColumns);
          // full location path generation
          if(!this.router.url.includes('/asset-detail') && fetchedData.reportData[0].hasOwnProperty('Location')) {
            for(let item of fetchedData.reportData) {
              let fullName = '';
              let fullLocation = item.Location;
              for(let level in fullLocation) {
                fullName = fullName + fullLocation[level] + '>';
              }
              fullName = fullName.substring(0, fullName.length - 1);
              item.Location = fullName;
              item.fullpath=fullName;
            }
          }
          // set the data source
          this.dataSource = new MatTableDataSource(assets);
          if (this.paginationFlag) {
            this.dataSource.paginator = this.paginator;
           // this.paginator.firstPage();
          }
          this.dataSource.sort = this.sort;
        }
    });
  }
  /**
   * (redirectToDetailPage)
   * Description:
   * this function redirects to detail page of assets
   */
  redirectToDetailPage(element) {

    if (typeof element['Action'] != "undefined"){
        return false;
    } else {
        if (this.router.url !== '/geofencelist') {
          const url = `/#/asset-detail;type=${this.AssetType};id=${element['_id']}`;
          window.open(url);
        }
    }
  }
  /**
   * (changeActiveStatus)
   * Description:
   * this function change status of the assets
   */
  changeActiveStatus(id, type){
   let deleteFlag=false;
   const toggle=document.getElementById(id);
    if (toggle.classList.contains('mat-checked')) {
      deleteFlag=true
    }
   let data={
      key:`${type}:${id}`,
      flag:deleteFlag
    }
    this.utilityService.post(data,"assets.toggleAssetStatus",res=>{
      console.log(res);

    })
    /* this.utilityService.deleteAssetKey(data,'toggleAssetStatus',res=>{
      console.log("Redis : "+(res));
      if (res==0) {
        let data = {
          key: `${type}:${id}`,
          flag: true
        }
      }

    }) */
  }
  /**
   * (onChangedPage)
   * Description:
   * this function is called when pagination comes in action
   */
  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.perPageItems = pageData.pageSize;
    this.sendPaginationDetails.emit({
      currentPage: this.currentPage,
      items: this.perPageItems
    });
  }
  /**
   * (applyFilter)
   * Description:
   * this function is called for filtered data form table (front-end side).
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    console.log(this.dataSource.filteredData);
  }

  /**
   * (selectAllStatus)
   * Description:
   * this function select all assets and mark them as active or deactive
   */
  selectAllStatus(){
    let selectAllCheckBox: any = document.getElementById('active_deactivate');
    if (selectAllCheckBox.checked){

      if (this.statusOption=='active') {

        this.utilityService.sendStatusMessage("active");
      }else{
        this.utilityService.sendStatusMessage("deactive");
      }


    }

  }

  /**
   * (applyFilterOnReportTable)
   * Description:
   * this function is called for filtered data form table (backend side only).
   */
  applyFilterOnReportTable(ev){
     this.sendFilterData.emit({text:ev['srcElement']['value']});
  }

  isOneOfRestrictedFields(fieldName) {
    return this.restrictedFields.indexOf(fieldName) > -1;
  }

  removeRow(id) {
    this.utilityService.deleteTableRow(id);
  }

  /**
   * (openOnMap)
   * Description:
   * In geofence alert, this function called to show geofence map page
   */
  openOnMap(alertId) {
    window.open('/#/geofence-alert/' + alertId);
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.tableDataSubscription) { this.tableDataSubscription.unsubscribe(); }
  }
}









